import * as $ from 'jquery';
import * as nav from './header/nav';
import * as hamburgerMenu from './hamburger-menu/hamburger-menu';
import * as slider from './slider/slider';
import * as animate from './animate/animate';
import * as accordion from './accordion/accordion';
import * as switchButton from './switch-button/switch-button';
import * as validation from './validation/validation';

$(function(){
  console.log('run');

  nav.init();
  hamburgerMenu.init();
  slider.init();
  animate.init();
  accordion.init();
  switchButton.init();
  validation.init();
});