import * as $ from 'jquery';
declare var require: any;
let validate = require('jquery-validation');

export const  init = () => {

    function recaptchaCallback() {
        $('#grecaptcha').valid();
    };

    $('form').each(function () {
        let thisForm = $(this);
        thisForm.submit(function (e:any) {
            e.preventDefault();
        }).validate({
            ignore: ".ignore",
            rules: {
                name: {
                    required: true
                },
                message: {
                    required: true
                },
                email: {
                    required: true,
                    email: true
                },
                password: {
                    required: true,
                    minlength: 5
                },
                password2: {
                    required: true,
                    equalTo: "#password",
                    minlength : 5,
                },
                check: {
                    required: true
                },
                grecaptcha: {
                    required: function () {
                        if (grecaptcha.getResponse() == '') {
                            return true;
                        } else {
                            return false;
                        }
                    }
                },
                check2: {
                    required: true
                },
            },
            messages: {
                name: "To pole jest wymagane",
                message: "To pole jest wymagane",
                email: {
                    required: "To pole jest wymagane",
                    email: "Twój e-mail musi być w formacie name@domain.com"
                },
                password: {
                    required: "Proszę podać hasło",
                    minlength: "Min. liczba znaków 5"
                },
                password2: {
                    required: "Proszę powtórzyć hasło",
                    equalTo: "Proszę podać takie samo hasło"
                },
                check: {
                    required: "To pole jest wymagane"
                },
                grecaptcha: {
                    required: "To pole jest wymagane"
                },
                check2: {
                    required: "To pole jest wymagane"
                }
            },
            submitHandler: function(form) {
                // form.submit();
                console.log(form)
                $.ajax({
                    type: "POST",
                    url: '#',
                    data: $(form).serialize(),
                    success: function() {
                        console.log('works');
                        // Nalezy dodać jeszcze przekierowanie do systemu danego formularza po zalogowaniu się lub po utworzeniu konta przez e-mail

                        // Pokazanie informacji o wysłaniu przypomnienia na skrzynkę
                        const remindPasswordWrapper = $('#remind-password').closest('.log-in__content');
                        remindPasswordWrapper.find('.log-in__item--remind-password').addClass('hide');
                        remindPasswordWrapper.find('.log-in__item--remind-password-send').addClass('show');
                        },
                    error: function(data) {
                        console.log('failed');
                    }
                });
                return false;
            }
        })
    });
};