import * as $ from 'jquery';

export const  init = () => {
    var span = $('.amount');

    $('input[type="checkbox"]').on('change', function(){
        if($(this).prop("checked") == true){
            span.each(function () {
                var showPrice = $(this).data('year');
                $(this).html(showPrice + '<small>zł</small>')

            });
        } else {
            span.each(function () {
                var showPrice = $(this).data('month');
                $(this).html(showPrice + '<small>zł</small>')
            });
        }
    });
};