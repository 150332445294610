import * as $ from 'jquery';
declare var require: any;
let Swiper = require('swiper');

export const  init = () => {
    var mySwiper = new Swiper ('.swiper-container', {
        // Optional parameters
        loop: true,
        speed: 800,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

    })
};